import round from 'lodash/round'
import capitalize from 'lodash/capitalize'
import { abbrNum } from '~js/pretty-printing'

export const defaultPrecision = 0

// export function update(element, valueAccessor, allBindingsAccessor) {
//   const value = ko.utils.unwrapObservable(valueAccessor())
//   const precision = ko.utils.unwrapObservable(allBindingsAccessor().precision) || ko.bindingHandlers.numericText.defaultPrecision
//   const formattedValue = value.toFixed(precision)
//   const finalFormatted = ko.bindingHandlers.numericText.withCommas(formattedValue)

//   ko.bindingHandlers.text.update(element, function() { return finalFormatted })
// }

export function withCommas(original: string | number | undefined) {
  /** Just make sure it's a string */
  original = original === undefined ? '0' : String(original)
  if (original.startsWith('0.00000')) { // if we get '0.00000..', just return '0'-- cleaner
    return '0'
  }
  original += ''
  const x = original.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

/**
 * @todo - Add tests for this function
 */
export function formatCurrency(cents: number, options: Intl.NumberFormatOptions = {}, lessPrecision: boolean = false) {
  const minimumFractionDigits = (lessPrecision && (cents % 100 === 0)) ? 0 : 2
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
    ...options,
    minimumFractionDigits
  })
  return formatter.format(cents / 100)
}

export function toPrecision(original: number | string, precision?: number) {
/** Just make sure it's a string */
  original = String(original)
  if (original.startsWith('0.00000')) {
    return '0'
  }
  if (!precision) {
    precision = defaultPrecision
  }
  /**
   * Previous math used here was not correct for precision rounding,
   * but Lodash's is.
   */
  return `${round(parseFloat(original), precision)}`
}

export function toEpcPrecision(original: number | string) {
  return toPrecision(original, 3)
}

export function toRcsPrecision(original: number | string) {
  return toPrecision(original, 2)
}

export function toCommPrecision(value: number | string) {
  /**
   * Is value a number or string? Original code was confusing
   * so instead, this makes explicit types.
   */
  const valueStr = String(value)
  const valueNum = parseFloat(valueStr)

  if (valueStr.startsWith('0.000')) {
    return '0'
  }
  if (valueNum < 1000) {
    return String(round(valueNum, 2).toFixed(2)) // need the toFixed to avoid: $156.6
  }
  return abbrNum(valueNum, 1)
}

export function toClickPrecision(value: number) {
  return abbrNum(value, 1)
}

export function capitalizeFirstLetter(value: any) {
  return capitalize(value)
}

export function abbrNumClicks(value: number) {
  return abbrNum(value, 1)
}

export function limitToXChars(original: string, x: number) {
  if (original != null && original.length > x) {
    let newVal = original.substr(0, x)
    if (newVal.charAt(x - 2) === ' ') {
      newVal = newVal.substr(0, x - 2)
    } else if (newVal.charAt(x - 3) === ' ') {
      newVal = newVal.substr(0, x - 3)
    }
    return newVal + '..'
  }
  return original
}
