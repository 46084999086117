import { useCurrentElement } from '@vueuse/core'
import {
  type ObservableArray,
  type Computed,
  type Observable,
  isObservable,
  isObservableArray,
  computed,
  observable,
  observableArray
} from 'vue-observables'

export {
  observable,
  observableArray,
  computed,
  isObservable,
  isObservableArray,
  type Observable,
  type ObservableArray,
  type Computed
}

// export const isObservableArray = (value: unknown): value is ObservableArray<any> => {
//   return isObservable(value) && Array.isArray(value())
// }

export type PureComputed<T> = Computed<T>

export * from './auto-bind'
export * from './mapping'
export * from './validation'
export * from './image'
export * from './use-script'

export const getTemplateElement = () => {
  let el = useCurrentElement().value as Element | null

  /**
   * Skip text or comment nodes, which are output
   * when Vue preserves comments, which we're doing for
   * Knockout support
   */
  if (el?.nodeName.startsWith('#')) {
    el = el.nextElementSibling
  }

  if (!el) {
    throw new Error('An element could not be found. (Was this called in onMounted?)')
  }
  return el
}

/** @see https://stackoverflow.com/questions/42950967/how-to-reference-text-thats-in-slot-slot-in-vue-js */

export const getSlotChildrenText = (children: any[]): string => children.map(node => {
  if (!node.children || typeof node.children === 'string') return node.children || ''
  else if (Array.isArray(node.children)) return getSlotChildrenText(node.children)
  else if (node.children.default) return getSlotChildrenText(node.children.default())
}).join('')
