<script setup lang="ts">
/**
 * A convenience wrapper to link buttons with the
 * panels they activate.
 */
import { ref, watch, onMounted } from 'vue'
import type OverlayPanel from 'primevue/overlaypanel'
import type Dialog from 'primevue/dialog'
import type Menu from 'primevue/menu'

defineOptions({
  inheritAttrs: false
})

const component = ref<OverlayPanel | Dialog | Menu>()
const visible = defineModel<boolean>('visible', { default: false })

const emit = defineEmits(['show', 'hide'])

const handleShow = (e: MouseEvent) => {
  const pnl = component.value
  visible.value = true
  emit('show')
  if (!pnl) {
    return
  }
  'show' in pnl && pnl?.show(e)
}

const handleHide = () => {
  const pnl = component.value
  visible.value = false
  emit('hide')
  if (!pnl) {
    return
  }
  'hide' in pnl && pnl?.hide()
}

const handleToggle = (e: MouseEvent) => {
  const isVisible =
    (component.value as any)?.overlayVisible
    ?? (component.value as any)?.visible
    ?? visible.value

  if (!isVisible) {
    handleShow(e)
  } else {
    handleHide()
  }
}

const slotProps = {
  panelRef: component,
  visible,
  show: handleShow,
  hide: handleHide,
  toggle: handleToggle
}

export type DefaultSlotProps = typeof slotProps

defineSlots<{
  default(props: DefaultSlotProps): void
}>()

onMounted(() => {
  const pnl = component.value
  if (!pnl) {
    return
  }
  watch(() => (pnl as any).visible, isVisible => {
    visible.value = isVisible
  })
})
</script>

<template>
  <slot v-bind="slotProps" />
</template>
