<script setup lang="ts">

/**
 * This is a component wrapper for PrimeVue's Checkbox,
 * to standardize how we use this.
 */
import PrimeCheckbox, {
  type CheckboxProps,
  type CheckboxEmits
} from 'primevue/checkbox'
import { useSlots, computed } from 'vue'

interface Props extends CheckboxProps {
  label?: string
  size?: 'sm' | 'md'
}
const props = withDefaults(defineProps<Props>(), {
  size: 'md'
})
const checked = defineModel<CheckboxProps['modelValue']>()
const slots = useSlots()
const finalLabel = computed(() => {
  return props.label ?? slots.default
})
</script>

<template>
  <label :class="size">
    <PrimeCheckbox
      v-bind="$props"
      v-model="checked"
      :binary="true"
    />
    <span v-if="finalLabel" v-html="finalLabel"></span>
    <slot></slot>
  </label>
</template>

<style lang="less">
.p-checkbox {
  display: inline-flex;
  align-items: center;
  .p-checkbox-box {
    color: white;
  }
}
</style>

<style scoped lang="less">
label {
  display: inline-flex;
  gap: 7px;
  align-items: flex-start;
  vertical-align: baseline;
  user-select: none;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 0.95rem;
  padding-top: 1px;
}
span {
  display: block;
}
label.sm {
  font-size: 1.4rem;
}
</style>
