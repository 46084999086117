<script setup lang="ts">
import { type Space, space as spaceTokens } from '~/components/ui/tokens'
import { computed } from 'vue'
import { type StringKeyOf } from 'type-fest'

/**
 * Standard Stack component
 * @see https://every-layout.dev/layouts/stack/
*/
const props = withDefaults(
  defineProps<{
    space?: Space
    is?: StringKeyOf<HTMLElementTagNameMap>
  }>(), {
    is: 'div',
    space: 'gap10'
  }
)

const spaceValue = computed(
  () =>
    props.space in spaceTokens
      ? (spaceTokens as any)[props.space]
      : props.space
)
</script>

<template>
  <component :is="is" class="stack">
    <slot />
  </component>
</template>

<style scoped lang="scss">
.stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: v-bind(spaceValue);
  max-width: 100%;
  min-width: 0;
  /** Remove any externally-applied margins */
  :deep(> *) {
    margin-block: 0;
  }
  /** Not sure if this is necessary, but might be good DX */
  :deep(> :is(button, .p-button, .btn, .button)) {
    align-self: flex-start;
  }
}
</style>
