<script lang="ts">
import Badge from 'primevue/badge'
import SpinnerIcon from 'primevue/icons/spinner'
// @ts-expect-error Ignore that it can't find types

import BaseButton from 'primevue/button/BaseButton.vue'
import ALink from './ALink.vue'
import { createReusableTemplate } from '@vueuse/core'
import { getSlotChildrenText } from '~/components/utils'

/**
 * Copied mostly from existing PrimeVue Button implementation.
 * This is abstracted to support router links (`<a>` tags)
*/
export default {
  /**
   * Vue has a bug where <component :is> will render this component
   * if it has the same name as the component it's being used in,
   * regardless of casing.
   *
   * Not sure if this is tracked?
   */
  name: 'CustomButton',
  extends: BaseButton,
  setup() {
    const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
    return { DefineTemplate, ReuseTemplate }
  },
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    /** Remove all button padding */
    trim: Boolean,
    href: String,
    to: String,
    icon: Boolean
  },
  methods: {
    getPTOptions(key: string) {
      return this.ptm(key, {
        context: {
          disabled: this.disabled
        }
      })
    }
  },
  computed: {
    defaultSlotText() {
      return ((this.hasDefaultSlot && getSlotChildrenText(this.$slots.default())) || '').trim()
    },
    tag() {
      return this.href || this.to ? ALink : 'button'
    },
    disabled() {
      return this.$attrs.disabled || this.$attrs.disabled === '' || this.loading
    },
    defaultAriaLabel() {
      if (this.defaultSlotText) {
        return this.defaultSlotText
      }
      return this.label ? this.label + (this.badge ? ' ' + this.badge : '') : this.$attrs.ariaLabel
    },
    hasIcon() {
      return !this.hasDefaultSlot && this.$slots.icon
    },
    hasDefaultSlot() {
      return !!this.$slots.default
    },
    hasLabel() {
      return this.label || this.hasDefaultSlot
    }
  },
  components: {
    SpinnerIcon,
    Badge
  }
}
</script>

<template>
  <component
    :is="tag"
    v-wave
    :class="[cx('root'), variant, size, !hasLabel && 'no-label', trim && 'trim', variant === 'link' && 'p-button-link']"
    :type="tag === 'button' ? 'button' : undefined"
    :aria-label="defaultAriaLabel"
    :disabled="disabled"
    v-bind="getPTOptions('root')"
    :data-pc-severity="severity"
    :href="href"
    :to="to"
  >
    <component :is="DefineTemplate">
      <slot v-if="loading" name="loadingicon" :class="[cx('loadingIcon'), cx('icon')]">
        <span v-if="loadingIcon" :class="[cx('loadingIcon'), cx('icon'), loadingIcon]" v-bind="ptm('loadingIcon')" />
        <SpinnerIcon
          v-else
          :class="[cx('loadingIcon'), cx('icon')]"
          spin
          v-bind="ptm('loadingIcon')"
        />
      </slot>
      <slot v-else name="icon"></slot>
      <slot>
        <span v-if="label" :class="cx('label')" v-bind="ptm('label')">{{ label || '&nbsp;' }}</span>
      </slot>

      <Badge
        v-if="badge"
        :value="badge"
        :class="badgeClass"
        :severity="badgeSeverity"
        :unstyled="unstyled"
        v-bind="ptm('badge')"
      />
    </component>
    <!-- Create an anchor wrapper to make this look / behave like a link -->
    <a href="#" v-if="link && !href && !to" @click.prevent>
      <component :is="ReuseTemplate" />
    </a>
    <component :is="ReuseTemplate" v-else />
  </component>
</template>

<style scoped lang="less">
.p-button:not(.link) {
  gap: 0.5rem;
  border-radius: 4px;
  outline: 1px solid transparent;
  padding: 0.45rem 0.9rem;
  text-decoration: none;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  background: transparent;
  justify-content: center;
  font-weight: 600;
  .p-inputgroup &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .p-inputgroup &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:focus {
    outline-color: var(--primary-color);
  }
  &.no-label {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: auto;
  }

  .p-inputgroup & {
    &:has(+ *) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.p-button-sm, &.x-small {
    padding-left: 0.666rem;
    padding-right: 0.666rem;
    font-size: (13rem / 14);
    line-height: 1.2;
    &.no-label {
      padding-left: 0.333rem;
      padding-right: 0.333rem;
      padding: 0.333rem;
    }
  }

  &.x-small {
    padding: 0.25rem 0.5rem;
    font-size: (12rem / 14);
    line-height: 1.2;
  }

  &.primary {
    background: var(--primary-color);
    color: var(--primary-color-text);
    &:not(.p-disabled):hover {
      background: var(--primary-color-hover);
    }
  }
  &.secondary {
    color: var(--text-color);
    background: var(--surface-0);
    border-color: var(--surface-300);
  }

  &.p-button-rounded {
    width: 2.25rem;
    height: 2.25rem;
    padding: 0;
    border-radius: 50%;
    aspect-ratio: 1;
    &.p-button-sm {
      width: 1.875rem;
      height: 1.875rem;
      min-height: 2rem;
    }
  }
  &.p-button-plain {
    background: transparent;
    border: none;
    color: var(--text-color-secondary);
  }
  &.p-button-plain, &.secondary {
    &:not(.p-disabled):hover {
      background: var(--surface-100);
    }
  }
  &.p-button-link {
    background: none;
    border: none;
    color: var(--primary-color);
    text-decoration: underline;
    padding: 0;
  }
  &.p-button-outlined {
    background-color: transparent;
    color: inherit;
    border-color: var(--surface-400);
    &:not(.p-disabled):hover {
      background-color: var(--translucent-surface-0);
    }
  }
  &.trim {
    padding: 0;
    min-height: 0;
    height: auto;
    width: auto;
  }
}
.link {
  display: inline-flex;
  align-self: flex-start;
  padding: 0;
  background: none;
  text-decoration: underline;
  &:hover {
    opacity: 0.8;
  }
}
</style>
