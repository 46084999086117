import type { LiteralUnion } from 'type-fest'

export const space = {
  gap0: '0',
  gap2: '0.143rem',
  gap4: '0.286rem',
  gap6: '0.429rem',
  gap8: '0.571rem',
  gap10: '0.714rem',
  gap12: '0.857rem',
  gap14: '1rem',
  gap16: '1.143rem',
  gap20: '1.429rem',
  gap24: '1.714rem',
  gap28: '2rem',
  gap36: '2.571rem'
} as const

export type Space = LiteralUnion<keyof typeof space, string>
